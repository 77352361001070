(function () {
  $('.hamburger-btn').on('click', function (e) {
    e.preventDefault();

    $(this).closest('.header')
      .toggleClass('nav-opened')
      .find('.header__overlay')
      .stop()
      .fadeToggle();
  })

  $('.header__overlay').on('click', function () {
    $(this)
      .stop()
      .fadeOut()
      .closest('.header')
      .removeClass('nav-opened')
  })

  $(window).on('scroll', function(e) {
    if (window.innerWidth < 768) return;

    const scrollTop = window.pageYOffset;
    const mainWrapperHeight = $('.main-wrapper').outerHeight();
    // debugger
    if (scrollTop > (mainWrapperHeight - window.innerHeight)) {
      $('.header').addClass('header--bottom');
    } else {
      $('.header').removeClass('header--bottom');
    }
    console.log()
  })

  // $('.header a[rel="sections-slider"]').on('click', function (e) {
  //   e.preventDefault();
  //   const href = $(this).attr('href');

  //   const [url, sectionIdx] = href.split('#section-');
  //   debugger
  //   history.pushState({
  //     sectionIdx
  //   }, '', url)
  // })
})()