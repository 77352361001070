(function() {
  // $([document.documentElement, document.body, window]).scrollTop(0);
  // window.scrollTo(0, 0);

  const slider = $('.sections-slider');
  const sliderNavLinks = $("a[rel='sections-slider']");

  if (!slider.length) {
    $('body').addClass('no-slider');
    return;
  }

  let currentScreenIdx = 0;

  const hash = location.hash;

  function getSectionIndexByHash(hash) {
    const appropriateLink = sliderNavLinks.filter(function() {
      return (
        '#' +
          $(this)
            .text()
            .toLowerCase()
            .split(' ')
            .join('-') ===
        hash
      );
    });

    return appropriateLink.attr('href').split('#idx-')[1];
  }

  let transitionFromInnerPage = false;

  if (hash) {
    if (hash.indexOf('#idx-') !== -1) {
      transitionFromInnerPage = true;
      // Transition by url
      currentScreenIdx = +hash.split('#idx-')[1];
      removeHash();
    } else {
      currentScreenIdx = getSectionIndexByHash(hash);
    }
  }

  const sections = [];

  function calculateSections() {
    $('section[data-section-idx]').each(function(indx) {
      sections[indx] = {
        start: $(this).offset().top,
        end: $(this).offset().top + $(this).outerHeight()
      };
    });
  }

  $(window).on('load resize', debounce(calculateSections, 50));

  function setMenuActiveLink(idx, pushToState) {
    sliderNavLinks.filter('.active').removeClass('active');
    sliderNavLinks.filter(`[href*="${idx}"]`).addClass('active');

    if (pushToState) {
      history.pushState(
        '',
        document.title,
        window.location.pathname +
          window.location.search +
          '#' +
          sliderNavLinks
            .filter('.active')
            .text()
            .toLowerCase()
            .split(' ')
            .join('-')
      );
    }
  }

  $(window).on('scroll', function() {
    if (window.innerWidth >= 768) return;
    const scrollTop = $(window).scrollTop() + 500;
    for (let i = 0; i < sections.length; i++) {
      if (scrollTop >= sections[i].start && scrollTop < sections[i].end) {
        activateScreen(i);
        return;
      }
    }
  });

  $(document).on('loaded', function() {
    if (!transitionFromInnerPage) {
      $('body').addClass('trigger-animations');
      bodyScrollLock.disableBodyScroll(document.documentElement);

      setTimeout(() => {
        $('.intro').fadeOut();
        bodyScrollLock.clearAllBodyScrollLocks();
        moveToScreen(currentScreenIdx);
        $('body').removeClass('trigger-animations');
      }, 4000);
    } else {
      $('.intro').hide();
      moveToScreen(currentScreenIdx);
    }
  });

  let isScrollNow = false;
  let isSlider = window.innerWidth >= 768;

  function getActiveScreenIdx() {
    const href = sliderNavLinks.filter('.active').attr('href');
    if (!href) return;
    return +href.split('#idx-')[1];
  }

  let isReinit = false;

  slider
    .on('destroy', function(ev, slick) {
      let carousel = $(this),
        reinit = debounce(function() {
          if (slick.activeBreakpoint > window.innerWidth) {
            return;
          }

          isReinit = true;

          carousel.slick({
            ...slick.options,
            currentScreenIdx: getActiveScreenIdx()
          });

          window.removeEventListener('resize', reinit);
        }, 500);

      calculateSections();
      window.addEventListener('resize', reinit);
    })
    .on('init', function() {
      if (isReinit) {
        moveToScreen(getActiveScreenIdx(), true);
      }
    })
    .slick({
      currentScreenIdx,
      arrows: false,
      infinite: false,
      swipe: false,
      cssEase: 'linear',
      speed: 1000,
      touchMove: false,
      responsive: [
        {
          breakpoint: 768,
          settings: 'unslick'
        }
      ]
    });

  function deactivateScreen(idx) {
    if (idx !== undefined) {
      $(`#section-${idx}`)
        .removeClass('screen-loaded')
        .children()
        .css('visibility', 'hidden');
    }
  }

  function activateScreen(idx, force) {
    const prevSectionIdx = getActiveScreenIdx();
    if (!force && idx == prevSectionIdx) return;
    const currentSection = $(`#section-${idx}`);

    if (
      !force &&
      currentSection.hasClass('screen-loaded') &&
      window.innerWidth < 768
    ) {
      setMenuActiveLink(+idx, true);
      return;
    }

    currentSection
      .children()
      .css('visibility', 'visible')
      .hide(0, function() {
        $(this).show(0);
      });
    const loadTime = currentSection.attr('data-load-time') || 0;

    setTimeout(() => {
      currentSection.addClass('screen-loaded');
    }, loadTime);

    if (force) return;

    if (window.innerWidth >= 768) {
      setMenuActiveLink(+idx, true);
      deactivateScreen(prevSectionIdx);
    } else {
      setMenuActiveLink(+idx, true);
    }
  }

  slider.on('afterChange', function(e, slick, currentSlide) {
    activateScreen(currentSlide);
  });

  slider.on('wheel', function(e) {
    if (window.innerWidth < 768) {
      return;
    }
    e.preventDefault();

    if (isScrollNow || e.originalEvent.wheelDeltaX !== 0) return;

    isScrollNow = true;

    setTimeout(() => {
      isScrollNow = false;
    }, 1500);

    if (e.originalEvent.deltaY > 0) {
      $(this).slick('slickNext');
    } else if (e.originalEvent.deltaY < 0) {
      $(this).slick('slickPrev');
    }
  });

  const hammertime = new Hammer(document.documentElement, {});
  hammertime.get('swipe').set({ direction: Hammer.DIRECTION_VERTICAL });
  hammertime.on('swipeup swipedown', function(e) {
    if (window.innerWidth < 768) {
      return;
    }

    if (isScrollNow) return;

    isScrollNow = true;

    setTimeout(() => {
      isScrollNow = false;
    }, 1500);

    if (e.type === 'swipeup') {
      slider.slick('slickNext');
    } else if (e.type === 'swipedown') {
      slider.slick('slickPrev');
    }
  });

  sliderNavLinks.on('click', function(e) {
    e.preventDefault();
    $('.header')
      .removeClass('nav-opened')
      .find('.header__overlay')
      .fadeOut();
    const sectionIdx = $(this)
      .attr('href')
      .split('#idx-')[1];

    moveToScreen(sectionIdx);
  });

  $('.header__logo').on('click', function(e) {
    e.preventDefault();
    moveToScreen(0);
  });

  function moveToScreen(sectionIdx, instant) {
    if (window.innerWidth < 768) {
      const section = $(`[data-section-idx="${sectionIdx}"]`);

      if (!section.length) return;

      $([document.documentElement, document.body]).animate(
        {
          scrollTop: section.offset().top - 50
        },
        instant ? 0 : 1000
      );

      activateScreen(sectionIdx, instant);
    } else {
      instant && activateScreen(sectionIdx, instant);
      slider.slick('slickGoTo', +sectionIdx, instant);
    }
  }

  $(window).on(
    'resize',
    debounce(function() {
      if (window.innerWidth < 768 && isSlider) {
        const currentIndex = getActiveScreenIdx();
        moveToScreen(currentIndex, true);
        isSlider = false;
      } else if (window.innerWidth >= 768 && !isSlider) {
        $('section[data-section-idx]')
          .removeClass('screen-loaded')
          .children()
          .css('visibility', 'hidden');
        const currentIndex = getActiveScreenIdx();
        moveToScreen(currentIndex, true);
        $([document.documentElement, document.body]).scrollTop(0);
        isSlider = true;
      }
    }, 1000)
  );
})();

function debounce(f, ms) {
  let timer = null;

  return function(...args) {
    const onComplete = () => {
      f.apply(this, args);
      timer = null;
    };

    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(onComplete, ms);
  };
}

function removeHash() {
  history.pushState(
    '',
    document.title,
    window.location.pathname + window.location.search
  );
}
