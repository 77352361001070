(function() {
  const $agency = $('.agency');

  if (!$agency.length) return;

  // $(window).on('resize', debounce(function () {
  //   if (window.innerWidth < 768) {
  //     pauseVideo();
  //   }
  // }, 500))

  let isJustStarted = false;
  let timePaused = 0;

  function playVideo() {
    if (isJustStarted) return;

    const video = $agency
      .removeClass('video-preview')
      .addClass('video-playing')
      .find('video')
      .get(0);

    video.playbackRate = 1;
    video.muted = false;

    // video.currentTime = timePaused;
    video.currentTime = 0;

    // video.addEventListener('pause', function () {
    //   video.muted = true;
    //   timePaused = video.currentTime
    // }, {
    //   once: true
    // })
    video.play();

    $(window).on('scroll', pauseVideo);

    isJustStarted = true;
    setTimeout(() => {
      isJustStarted = false;
    }, 1000);
  }

  function previewVideo() {
    if (isJustStarted) return;

    const video = $agency
      .removeClass('video-playing')
      .addClass('video-preview')
      .find('video')
      .get(0);
    video.playbackRate = 1;
    video.play();
    // isJustStarted = true;
    // setTimeout(() => {
    //   isJustStarted = false;
    // }, 1000)
  }

  function pauseVideo() {
    $(window).off('scroll', pauseVideo);

    $agency.removeClass('video-playing video-preview');
    const video = $agency
      .removeClass('video-playing video-preview')
      .find('video')
      .get(0);
    // $agency.removeClass('video-playing video-preview').find('video').get(0).pause();
    video.muted = true;
    timePaused = video.currentTime;
  }

  function triggerVideo(e) {
    e.stopPropagation()
    if (isJustStarted) return;

    $agency.hasClass('video-playing') ? pauseVideo() : playVideo();
  }

  $('body').on(
    'click',
    '.screen-loaded .agency__video-play-btn, .screen-loaded .agency__video-close-btn',
    triggerVideo
  );

  $('body').on('click', '.screen-loaded .agency__video-wrapper', function() {
    if (window.innerWidth > 767 || $agency.hasClass('video-playing')) {
      return;
    }

    playVideo();
  });
  
  $('body').on(
    'wheel',
    '.screen-loaded .agency__video-wrapper',
    debounce(pauseVideo, 500)
  );
})();

function debounce(func, wait, immediate) {
  var timeout;
  return function() {
    var context = this,
      args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}
