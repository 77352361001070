$(document).ready(() => {
  objectFitImages();
  svg4everybody();

  // add target blank to external links
  var siteUrl = window.location.hostname;
  $('a[href*="//"]:not([href*="' + siteUrl + '"])').attr({
    target: '_blank',
    rel: 'noopener noreferrer'
  });

  require('./agency');
  require('./sections-slider');
  require('./nav');
  require('./tabs-nav');
  require('./fiercely');
  require('./goat');
});

$(window).on('load', function() {
  $('#preloader').fadeOut();
  AOS.init({ once: true, duration: 1200 });
  $(document).trigger('loaded');
});
