(function() {
  if (!$('.fiercely').length) return;

  function is_touch_device() {
    var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
    var mq = function(query) {
      return window.matchMedia(query).matches;
    };

    if (
      'ontouchstart' in window ||
      (window.DocumentTouch && document instanceof DocumentTouch)
    ) {
      return true;
    }

    // include the 'heartz' as a way to have a non matching MQ to help terminate the join
    // https://git.io/vznFH
    var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join(
      ''
    );
    return mq(query);
  }

  function setPadding() {
    const fiercelyInnerHeight = $('.fiercely__inner').outerHeight();
    const fiercelyHeight = $('.fiercely').outerHeight();
    const padding =
      fiercelyHeight / 2 -
      fiercelyInnerHeight / 2 -
      (window.innerWidth < 768 ? 30 : 0);
    $('.fiercely').css('paddingTop', padding + 'px');
  }

  setPadding();

  $(window).on('resize', debounce(setPadding, 500));

  $('.fiercely__word').on('mouseenter', function() {
    $('.fiercely__word.hovered').trigger('mouseleave');

    $(this)
      .addClass('hovered')
      .closest('.fiercely__word-wrapper')
      .find('.fiercely__word-text')
      .css({
        opacity: '0',
        top: '-50px'
      })
      .stop()
      .slideDown(400)
      .animate(
        {
          opacity: '1',
          top: '0'
        },
        {
          duration: 600,
          queue: false
        }
      );
  });

  $('.fiercely__word').on('mouseleave', function() {
    $(this)
      .removeClass('hovered')
      .closest('.fiercely__word-wrapper')
      .find('.fiercely__word-text')
      .css({
        opacity: '1',
        top: '0'
      })
      .stop()
      .animate(
        {
          opacity: '0',
          top: '-50px'
        },
        400
      )
      .slideUp({
        queue: false,
        duration: 600
      });
  });

  if (is_touch_device()) {
    if (window.innerWidth < 576) {
      $('.fiercely__top-word .fiercely__word--mobile').trigger('mouseenter');
    } else {
      $(
        '.fiercely__top-word .fiercely__word:not(.fiercely__word--mobile)'
      ).trigger('mouseenter');
    }
  }
})();

function debounce(f, ms) {
  let timer = null;

  return function(...args) {
    const onComplete = () => {
      f.apply(this, args);
      timer = null;
    };

    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(onComplete, ms);
  };
}
