(function() {
  const blog = $('.blog');

  if (!blog.length) return;

  var Defaults = $.fn.select2.amd.require('select2/defaults');
  $.extend(Defaults.defaults, {
    searchInputPlaceholder: ''
  });
  var SearchDropdown = $.fn.select2.amd.require('select2/dropdown/search');
  var _renderSearchDropdown = SearchDropdown.prototype.render;
  SearchDropdown.prototype.render = function(decorated) {
    // invoke parent method
    var $rendered = _renderSearchDropdown.apply(
      this,
      Array.prototype.slice.apply(arguments)
    );
    this.$search.attr(
      'placeholder',
      this.options.get('searchInputPlaceholder')
    );
    return $rendered;
  };

  var countPost = 0;

  function hideBlogPost() {
    var items = $('.blog-post-active');
    if (items.length > 6) {
      for (var i = 0; i < items.length; i++) {
        items[i].classList.add('blog-post-hidden');
      }
      document.getElementById('loadMore').style.display = 'inline-block';
    }
  }
  function showBlogPost() {
    var items = $('.blog-post-active');
    if (items.length <= 6) {
      document.getElementById('loadMore').style.display = 'none';
    } else {
      for (var i = 0; i < 6; i++) {
        items[i].classList.remove('blog-post-hidden');
        items[i].classList.add('blog-post-visible');
      }
    }
    countPost = 6;
  }

  const select = $('.blog__category-select');
  select.select2({
    width: 'resolve',
    theme: 'custom-theme',
    containerCssClass: 'styled-select__container',
    dropdownCssClass: 'styled-select__dropdown',
    minimumResultsForSearch: 3,
    searchInputPlaceholder: 'Search...'
  });

  var selectedCategory = '';
  var posts = $('#posts .post');
  $('.blog__category .blog__category-btn').click(function(e) {
    $('.blog__category .blog__category-btn.active').removeClass('active');
    selectedCategory = $(this).attr('data-rel');

    $(this).addClass('active');
    
    if (!$('#posts .post').length) {
      return;
    }

    e.preventDefault();

    $('#posts .post').removeClass('blog-post-active');
    $('#posts .post').removeClass('blog-post-hidden');
    $('#posts .post').removeClass('blog-post-visible');
    countPost = 0;


    select.val(selectedCategory);
    select.trigger('change');

    $('#posts').fadeTo(100, 0.1);
    $('#posts .post').fadeOut();

    const loadMoreBtn = document.getElementById('loadMore');

    if (loadMoreBtn) {
      loadMoreBtn.style.display = 'none';
    }

    for (var i = 0; i < posts.length; i++) {
      var carRel = posts[i].dataset.rel;
      var car = carRel.indexOf(selectedCategory);
      if (car == 0) {
        car = 1;
      }
      if (car == -1) {
        car = 0;
      }
      if (car) {
        $(posts[i])
          .fadeIn()
          .addClass('blog-post-active');
      }
    }
    $('#posts').fadeTo(300, 1);

    setTimeout(function() {
      hideBlogPost();
      showBlogPost();
    }, 100);
  });

  select.on('change', function(e) {
    const category = e.target.value;
    if (selectedCategory === category) return;

    const btn = $(`.blog__category-btn[data-rel="${category}"]`);
    if (!$('#posts .post').length) {
      window.location = btn.attr('href');
    } else {
      btn.trigger('click');
    }
  });

  $('.blog__category-btn.active').trigger('click');

  const button = $('#loadMore');

  if (!button.length) return;

  button.on('click', function(e) {
    e.preventDefault();
    const articles = $('.blog-post-active');
    let to;

    if (countPost + 2 < articles.length) {
      to = countPost + 2;
    } else {
      to = articles.length;
      document.getElementById('loadMore').style.display = 'none';
    }

    for (let i = countPost; i < to; i++) {
      articles[i].classList.remove('blog-post-hidden');
      articles[i].classList.add(
        'blog-post-visible',
        'animated',
        'fadeIn',
        'slow'
      );
      countPost++;

      setTimeout(() => {
        articles[i].classList.remove('animated', 'fadeIn', 'slow');
      }, 2000);
    }
  });
})();
