(function() {
  const goatParts = $('.goat-part');
  if (!goatParts.length) return;

  goatParts.on('mouseenter', function() {
    $('.goat-part.active').removeClass('active');
    $('.capabilities__item.active').removeClass('active');
    $(this).addClass('active');
    $('.capabilities__item[data-title="' + $(this).attr('id') + '"]').addClass(
      'active'
    );
  });
  goatParts.on('mouseleave', function() {
    $(this).removeClass('active');
    $(
      '.capabilities__item[data-title="' + $(this).attr('id') + '"]'
    ).removeClass('active');
  });

  const initialShown = $('.capabilities__item.active').data('title');

  if (initialShown) {
    goatParts.filter('#' + initialShown).trigger('mouseenter');
  }
})();
